.logo{
	width: 130px;
	height: 130px;
	padding: 5px;
	border-right: 1px solid black;
}

.App{
	padding-left: 10px;
	padding-right: 10px;
	font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
}

.sd-row:has( .even) {
	background-color: #ddd !important;
	padding-left: 5px !important;
	padding-right: 5px !important;
	padding-top:32px;
	margin-top: 0 !important;
}

.sd-row {
	margin-bottom: 3px;
}
.padding_lr {
	padding-left: 5px !important;
	padding-right: 5px !important;
}

.sd-body.sd-body--static {
	max-width: 100% !important;
}

.sd-btn{
	color: white !important;
	background-color: #0F62FE !important;
}

#sv-nav-next, #sv-nav-complete {
	margin-left: auto; 
    margin-right: 0;
}

.sd-btn:hover{
	background-color: #0F62FEAA !important
} 

.subtitle {
  	color: #aaa;
	text-align: left;
	
}

.container {
	max-width: 1400px;
	margin: auto;
}

.header {
	margin-top: 10px;
	display: flex;
}

.header-title {
	padding-left: 10px;
	text-align: left;
	
}
.header-title-h1 {
	font-size: 42px;
	line-height: 50px;
}