table{
    border-spacing: 0;
}
th{
    text-align: left;
    padding: 10px;
}
td{
    padding: 10px;
}

div{
    font-size: 1rem;
    line-height: 1.5rem;
}

/* h1{
    font-size: 64px;
    line-height: 64px;
} */

